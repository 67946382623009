import PageDashboard from '../components/PageDashboard'
import { graphql } from 'gatsby'

export const query = graphql`
  query PageDashboard {
    site {
      siteMetadata {
        title
      }
    }
    listing: mdx {
      frontmatter {
        id
      }
    }
  }
`
export default PageDashboard
